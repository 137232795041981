import request from '@/services/request'
import apis from '@/services/apis'
const endpoint = {
  suppliersRegister: 'suppliers/register',
  financierRegister: 'financiers/register',
  financiers: 'financiers',
  approveMultiple: 'invoices/approveMultipleInvoices',
  invoices: 'invoices',
  createRequestDocuments: 'invoices/createAndUploadRequestDocuments',
  uploadSupportingDocuments: 'invoices/uploadSupportingDocuments',
  uploadMtOneZeroThreeDocuments: 'invoices/uploadMtOneZeroThreeDocuments',
  previewFeesAndCharges: 'invoices/previewFeesAndCharges',
  sendReminder: 'sendReminder',
  accountPayable: 'accountPayable'
}

export const postFinancerRegister = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.financierRegister}`,
    data
  })
}
export const postSuppliersRegister = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.suppliersRegister}`,
    data
  })
}

export const getFinancersOrSuppliersOrganization = (roleName) => {
  return request.get({
    url: `${apis.finance}/${roleName}/organization`
  })
}
export const getFinancerDetails = (financierId) => {
  return request.get({
    url: `${apis.finance}/${endpoint.financiers}/${financierId}`
  })
}
export const getFinanceList = (pageSize = 50, pageNumber = 0, query) => {
  return request.get({
    url: `${apis.finance}/${endpoint.financiers}?pageSize=${pageSize}&pageNumber=${pageNumber}&${query}`
  })
}
export const createRequestDocuments = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.createRequestDocuments}`,
    data
  })
}
export const updateFinancingInvoice = (data, invoiceId) => {
  return request.put({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}`,
    data
  })
}

export const getFinancingInvoiceById = (invoiceId) => {
  return request.get({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}`
  })
}

export const getFinancingInvoiceList = (pageSize = 50, pageNumber = 0, query) => {
  return request.get({
    url: `${apis.finance}/${endpoint.invoices}?pageSize=${pageSize}&pageNumber=${pageNumber}&${query}`
  })
}
export const postMultipleFinanceAction = (invoiceIds, actionName) => {
  return request.post({
    url: `${apis.finance}/${endpoint.approveMultiple}`,
    data: {
      invoiceIds
    }
  })
}
export const putSingleFinanceAction = (invoiceId, actionName) => {
  return request.put({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}/${actionName}`
  })
}
export const uploadSupportingDocuments = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.uploadSupportingDocuments}`,
    data
  })
}
export const uploadMtOneZeroThreeDocuments = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.uploadMtOneZeroThreeDocuments}`,
    data
  })
}
export const completeSingleFinance = (invoiceId) => {
  return request.put({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}/complete`
  })
}
export const previewFeesAndCharges = (data) => {
  return request.post({
    url: `${apis.finance}/${endpoint.previewFeesAndCharges}`,
    data
  })
}
export const sendReminderEmail = (invoiceId, data) => {
  return request.post({
    url: `${apis.finance}/${apis.invoices}/${invoiceId}/${endpoint.sendReminder}`,
    data
  })
}
export const createOrUpdateAccountPayable = (payload, accountPayableId) => {
  const emails = payload.emails.replace(/\s*/g, '').split(';').filter(Boolean).map(e => { return { email: e } })
  const { personInChargeName, companyAddress, contactNumber } = payload
  const data = {
    companyName: payload.companyName.toUpperCase(),
    emails,
    personInChargeName,
    companyAddress,
    contactNumber
  }
  if (accountPayableId) {
    return request.put({
      url: `${apis.finance}/${endpoint.accountPayable}/${accountPayableId}`,
      data
    })
  } else {
    return request.post({
      url: `${apis.finance}/${endpoint.accountPayable}`,
      data
    })
  }
}
export const getAccountPayableList = (pageNumber = 0, pageSize = 999, query = 'filter&sort') => {
  return request.get({
    url: `${apis.finance}/${endpoint.accountPayable}?pageNumber=${pageNumber}&pageSize=${pageSize}&${query}`
  })
}
export const sendNoaAcknowledgementReminder = (invoiceId) => {
  return request.get({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}/sendNoaAcknowledgementReminder`
  })
}
export const updateAcknowledgeNoa = (invoiceId) => {
  const parmas = { invoiceId: invoiceId }
  return request.put({
    url: `${apis.finance}/${endpoint.invoices}/${invoiceId}/acknowledgeNoa`,
    data: parmas
  })
}
