<template>
   <!-- financer info -->
  <el-form
    ref="financerInfoForm"
    :rules="rules"
    :model="financerInfoFormData"
    label-position="top">
    <div class="form-block">
      <div class="title">{{ $t('financerInfo') }}</div>
      <el-row :gutter="10">
          <el-col :sm="12" :md="8" :lg="6" >
            <el-form-item label="Discount Rate (%)" prop="discountRatePercentage">
              <el-input-number
                style="width:100%"
                :precision="1"
                :min="0"
                v-model="financerInfoFormData.discountRatePercentage">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="6"  >
            <el-form-item label="Late Charges Fee (%)" prop="lateChargesPercentage">
              <el-input-number
                style="width:100%"
                :precision="1"
                :min="0"
                v-model="financerInfoFormData.lateChargesPercentage">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="24"  >
            <el-form-item label="Terms and Conditions" prop="termsConditions">
              <el-input type="textarea" :rows="3" v-model="financerInfoFormData.termsConditions"></el-input>
            </el-form-item>
          </el-col>
      </el-row>
    </div>
  </el-form>
</template>
<script>
import baseMixin from '@/mixins/base-mixin'
import { reqGreaterThanZeroValidation } from '@/utils/index.js'
import { postFinancerRegister } from '@/services/modules/finance'
export default {
  name: 'FinancerRegister',
  mixins: [baseMixin],
  data () {
    const financerInfoFormData = {
      discountRatePercentage: '',
      lateChargesPercentage: '',
      termsConditions: '',

    }
    const reqRule = [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]
    const reqGreaterThanZeroRule = [{ required: true, validator: this.reqGreaterThanZeroValidation, trigger: ['blur'] }]
    return {
      reqRule,
      reqGreaterThanZeroRule,
      financerInfoFormData,
    }
  },
  computed: {
    rules () {
      const _rules = {}
      for (var item in this.financerInfoFormData) {
        if (item === 'termsConditions') {
          _rules[item] = this.reqRule
        } else { _rules[item] = this.reqGreaterThanZeroRule }
      }
      return _rules
    }
  },
  created () {
  },
  methods: {
    reqGreaterThanZeroValidation,
    handleRegisteFinancer (isFinancer, name, sysOrgId) {
      const payload = { ...this.financerInfoFormData, name, sysOrgId }
      if (isFinancer) {
        postFinancerRegister(payload).then(resp => {
          // console.log(' postFinancerRegister resp', resp)
        })
      } else {
        const payload = { sysOrgId, name }
        postFinancerRegister(payload).then(resp => {
          // console.log(' postFinancerRegister resp', resp)
        })
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
