var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "financerInfoForm",
      attrs: {
        rules: _vm.rules,
        model: _vm.financerInfoFormData,
        "label-position": "top",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form-block" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("financerInfo"))),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Discount Rate (%)",
                        prop: "discountRatePercentage",
                      },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { precision: 1, min: 0 },
                        model: {
                          value:
                            _vm.financerInfoFormData.discountRatePercentage,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.financerInfoFormData,
                              "discountRatePercentage",
                              $$v
                            )
                          },
                          expression:
                            "financerInfoFormData.discountRatePercentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Late Charges Fee (%)",
                        prop: "lateChargesPercentage",
                      },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { precision: 1, min: 0 },
                        model: {
                          value: _vm.financerInfoFormData.lateChargesPercentage,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.financerInfoFormData,
                              "lateChargesPercentage",
                              $$v
                            )
                          },
                          expression:
                            "financerInfoFormData.lateChargesPercentage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { sm: 24, md: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Terms and Conditions",
                        prop: "termsConditions",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 3 },
                        model: {
                          value: _vm.financerInfoFormData.termsConditions,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.financerInfoFormData,
                              "termsConditions",
                              $$v
                            )
                          },
                          expression: "financerInfoFormData.termsConditions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }